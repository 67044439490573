<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar
        color="transparent"
      >
        <ion-buttons
          slot="end"
          class="mr-10 mt-10"
        >
          <a
            class="d-flex align-items-center"
            style="cursor: pointer;"
          >
            <span
              tappable
              class="text--white"
              @click="openLink"
            >
              {{ $t('Shop as a guest') }}
            </span>
            <i
              class="mdi mdi-cart text--primary ml-10"
              style="font-size: 25px;"
            />
          </a>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-y="false">
      <ion-grid
        class="container"
      >
        <ion-row
          class="middle-content center-logo"
        >
          <img
            :src="require('@/assets/images/homevision.svg')"
          >
        </ion-row>
        <ion-row class="middle-content">
          <ion-text class="text--gray">
            {{ $t('loginPageText') }}
          </ion-text>
        </ion-row>
        <ion-row
          size="3"
          class="bottom-buttons"
        >
          <ion-col
            align-self-center
          >
            <ion-button
              class="google-signin"
              @click="signInWithGoogle"
            >
              <ion-img
                style="max-width: 35px; margin-right: 5px;"
                :src="google"
              />
              <ion-label
                class="text--gray"
              >
                {{ $t('Sign up with Google') }}
              </ion-label>
            </ion-button>
            <ion-button
              v-if="platform.includes('ios')"
              class="apple-signin"
              @click="signInWithApple"
            >
              <ion-img
                style="max-width: 23px; margin-right: 10px; margin-bottom: 5px;"
                :src="apple"
              />
              <label
                class="text--gray"
              >{{ $t('Sign up with Apple') }}</label>
            </ion-button>
            <ion-button
              style="margin-top: 2vh; min-height: 50px;"
              class="signin-button"
              @click="$emit('signup')"
            >
              {{ $t('Sign up with email') }}
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="have-an-account">
          <ion-col>
            <ion-text
              tappable
              class="text--gray"
              @click="$router.push('/login')"
            >
              <span style="font-weight: 400;">{{ $t('Already have an account?') }}</span> <span class="signin-link">{{ $t('Sign In') }}</span>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { alertController } from '@ionic/vue'
import google from '@/assets/images/google.png'
import apple from '@/assets/images/apple.png'
// import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { GooglePlus } from '@ionic-native/google-plus/'
import { Plugins } from '@capacitor/core'

import { InAppBrowser } from '@ionic-native/in-app-browser/'

import jwtDecode from 'jwt-decode'
const { SignInWithApple } = Plugins

export default {
  name: 'SignUpIntro',
  components: {
  },
  emits: ['signup', 'OAuthSingUp'],
  data () {
    return {
      google,
      apple
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  methods: {
    async presentAlert () {
      const alert = await alertController
        .create({
          header: 'Not Implemented',
          subHeader: '',
          message: '',
          buttons: ['Dismiss']
        })
      await alert.present()
    },
    signInWithGoogle () {
      GooglePlus.login({})
        .then(response => {
          const name = response.displayName
          const email = response.email
          // console.log(response)
          this.$store.dispatch('OAuthSingUpCheckCredentials', { name, email })
            .then(response => {
              // console.log(response)
              if (response.data.type === 'Success') {
                this.$router.replace('/')
              } else if (response.data.type === 'False') {
                this.$emit('OAuthSingUp', { name, email })
              } else {
                this.presentAlertApple(this.$t('Something has failed, please try again'))
              }
            })
            .catch((error) => {
              console.log(error)
              this.presentAlertApple(error.data)
            })
        })
        .catch(error => {
          // this.presentAlertApple(this.$t('Something has failed, please try again'))
          console.log(error)
        })
    },
    signInWithApple () {
      SignInWithApple.Authorize()
        .then(async (res) => {
          if (res.response && res.response.identityToken) {
            const name = `${res.response.givenName} ${res.response.familyName}`
            let email = res.response.email
            const token = res.response.identityToken
            if (email === null) {
              const decoded = jwtDecode(token)
              email = decoded.email
            }
            this.$store.dispatch('OAuthSingUpCheckCredentials', { name, email })
              .then(response => {
                console.log(response)
                if (response.data.type === 'Success') {
                  this.$router.push('/')
                } else if (response.data.type === 'False') {
                  this.$emit('OAuthSingUp', { name, email })
                } else {
                  this.presentAlertApple(this.$t('Something has failed, please try again'))
                }
              })
              .catch((error) => {
                this.presentAlertApple(error.data)
              })
          }
        })
        .catch((response) => {
          // this.presentAlertApple(response)
        })
    },
    async presentAlertApple (res) {
      const alert = await alertController
        .create({
          header: 'Failure',
          subHeader: '',
          message: res,
          buttons: [this.$t('Dismiss')]
        })
      await alert.present()
    },
    openLink () {
      InAppBrowser.create('https://homevision.se/e-shop').show()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
  $offset: 20px;
  .middle-content {
    justify-content: center;
    padding: 0 $offset;

    img {
      padding-bottom: $offset;
    }
  }
  ion-toolbar{
    width: 100%;
    ion-buttons{
      position: absolute;
      right: 0;
      a{
        span{
          letter-spacing: 0.4px;
        }
      }
    }
  }
  .signin-link {
    font-weight: 400;
   color: colors(primary, base);
  }
  ion-button {
    height: 50px;
    text-transform: none;
  }
  .bottom-buttons {
    margin-top: 10vh !important;
  }
.google-signin {
    width: 100%;
    min-height: 50px;
    border-radius: 5px;
    @include background-item--dark-transparent;
    --box-shadow: none;
    font-size: 16px;
    font-weight: bold;
    padding: none;
    margin: 0 0 10px 0;
    --ripple-color: transparent;
    --background-hover: transparent !important;

    ion-icon {
      margin-right: 10px;
    }
  }
  .apple-signin {
    width: 100%;
    min-height: 50px;
    border-radius: 5px;
    @include background-item--dark-transparent;
    --box-shadow: none;
    font-size: 16px;
    font-weight: bold;
    padding: none;
    margin: 0 0 8px 0;

    ion-icon {
      margin-right: 10px;
    }
  }
  @media only screen and (min-width: 750px) {
  .container {
    margin-left: 20vw;
    margin-right: 20vw;
  }
    .background--login::after {
    background-size: 100% auto;
  }
}
@media only screen and (min-width: 1000px) {
  .container {
    margin-left: 30vw;
    margin-right: 30vw;
  }
    .background--login::after {
    background-size: 100% auto;
  }
}
@media only screen and (min-width: 1300px) {
  .container {
    margin-left: 35vw;
    margin-right: 35vw;
  }
  .background--login::after {
    background-size: 100% auto;
  }
}
.have-an-account {
  z-index: 100;
}
</style>
