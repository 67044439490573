<template>
  <ion-page>
    <div class="background--login" />
    <ion-content
      v-if="page===0"
      :scroll-y="false"
    >
      <SignUpIntro
        @signup="page ++"
        @OAuthSingUp="OAuthSingUp"
      />
    </ion-content>
    <ion-content
      v-else
      :scroll-y="false"
    >
      <ion-grid class="fixed-grid">
        <div class="ion-padding-start">
          <Header
            @onBackClick="page = 0; verificationFailed = false; loading = false; clearForm()"
          />
        </div>

        <ion-slides
          ref="slides"
          scrollbar="false"
          :options="{ slidesPerView: '1', zoom: false, allowTouchMove: false }"
          class="slides form--dark"
          :class="platform.includes('ios') ? 'form--dark-ios' : 'form--dark-md'"
          @ionSlideNextEnd="page ++"
          @ionSlidePrevEnd="page --"
        >
          <ion-slide>
            <ion-content class="ion-padding-start ion-padding-end welcome-content">
              <div>
                <h1>
                  {{ $t('Create account') }}
                </h1>
                <ion-text class="text--gray">
                  {{ $t('Please fill your login information') }}
                </ion-text>
                <ion-item lines="none">
                  <ion-label position="floating">
                    {{ $t('Name and Surname') }}
                  </ion-label>
                  <ion-input
                    id="name"
                    v-model="name"
                    class="capitalize"
                    type="text"
                    required
                    enterkeyhint="done"
                    @keydown.enter="closeKeyboard"
                  />
                </ion-item>

                <ion-item lines="none">
                  <ion-label position="floating">
                    {{ $t('Your email') }}
                  </ion-label>
                  <ion-input
                    id="email"
                    v-model="email"
                    type="email"
                    enterkeyhint="done"
                    required
                    @keydown.enter="closeKeyboard"
                  />
                </ion-item>

                <ion-item lines="none">
                  <ion-label position="floating">
                    {{ $t('Your phone number') }}
                  </ion-label>
                  <ion-input
                    id="phone-number"
                    v-model="phoneNumber"
                    type="number"
                    required
                    enterkeyhint="done"
                    @keydown.enter="closeKeyboard"
                  />
                </ion-item>
                <div style="min-height: 60px;">
                  <ion-item
                    color="transparent"
                    lines="none"
                    class="text--gray text-14 t-and-c"
                  >
                    <ion-checkbox
                      slot="start"
                      v-model="agreeToTC"
                    />
                    <!-- <a
                      class="text--primary ml-3"
                      @click="openTC(); $event.stopPropagation()"
                    >{{ $t('I agree to') }} {{ $t('Terms & Conditions and Privacy Policy') }}</a> -->
                    <div
                      style="display: inline-block;"
                    >
                      {{ $t('I agree to') }} <a
                        tappable
                        class="text--primary"
                        @click="openTC(); $event.stopPropagation()"
                      >{{ $t('Terms & Conditions') }} </a>
                      {{ $t('and') }}
                      <a
                        tappable
                        class="text--primary"
                        @click="openPP(); $event.stopPropagation()"
                      > {{ $t('Privacy Policy') }} </a>
                    </div>
                  </ion-item>
                </div>
                <ion-button
                  fill="clear"
                  color="transparent"
                  class="next-button"
                  expand="block"
                  :disabled="!name || !email || !phoneNumber || loading || !agreeToTC"
                  @click="sendEmailVerification"
                >
                  <ion-spinner
                    v-if="loading"
                    color="dark"
                    name="crescent"
                  />
                  <ion-label v-else>
                    {{ $t('Continue') }}
                  </ion-label>
                </ion-button>
              </div>
              <ion-row class="mt-20">
                <ion-col>
                  <ion-text class="text--gray">
                    {{ $t('Have an account?') }}
                  </ion-text>
                  <a
                    class="ml-5"
                    href="/login"
                  >
                    {{ $t('Sign In') }}
                  </a>
                </ion-col>
              </ion-row>
            </ion-content>
          </ion-slide>

          <ion-slide>
            <ion-content class="ion-padding welcome-content">
              <h1>{{ $t('Verification') }}</h1>
              <div>
                <ion-text class="text--gray">
                  {{ $t('VerificationEmailText') }}
                </ion-text>
              </div>
              <div class="mt-20">
                <ion-item
                  lines="none"
                  :class="verificationFailed ? 'red-border' : ''"
                >
                  <ion-label position="floating">
                    {{ $t('Enter the verification code') }}
                  </ion-label>
                  <ion-input
                    v-model="verificationCode"
                    type="text"
                    required
                    enterkeyhint="done"
                    @ionFocus="verificationFailed = false"
                  />
                </ion-item>
              </div>
              <ion-button
                fill="clear"
                color="transparent"
                class="next-button"
                expand="block"
                :disabled="!verificationCode || loading"
                @click="verifyMyEmail"
              >
                <ion-spinner
                  v-if="loading"
                  color="dark"
                  name="crescent"
                />
                <ion-label v-else>
                  {{ $t('Continue') }}
                </ion-label>
              </ion-button>
            </ion-content>
          </ion-slide>

          <ion-slide>
            <ion-content class="ion-padding welcome-content">
              <div>
                <h1>
                  {{ $t('Address details') }}
                </h1>
                <ion-text class="text--gray">
                  {{ $t('Please fill your address') }}
                </ion-text>
                <ion-item
                  lines="none"
                  style="margin-top: 25px"
                >
                  <ion-label position="floating">
                    {{ $t('Country') }}
                  </ion-label>
                  <ion-select
                    v-model="country"
                    :value="country"
                    :cancel-text="$t('Cancel')"
                    :ok-text="$t('Ok')"
                  >
                    <ion-select-option
                      v-for="c of countries"
                      :key="c.value"
                      :value="c.value"
                    >
                      {{ c.label }}
                    </ion-select-option>
                  </ion-select>
                  <i class="material-icons select-icon">expand_more</i>
                </ion-item>

                <ion-item lines="none">
                  <ion-label position="floating">
                    {{ $t('City') }}
                  </ion-label>
                  <ion-input
                    id="city"
                    v-model="city"
                    class="capitalize"
                    type="text"
                    required
                    enterkeyhint="done"
                    @keydown.enter="closeKeyboard"
                  />
                </ion-item>

                <ion-item lines="none">
                  <ion-label position="floating">
                    {{ $t('Address') }}
                  </ion-label>
                  <ion-input
                    id="street"
                    v-model="street"
                    class="capitalize"
                    type="text"
                    enterkeyhint="done"
                    required
                    @keydown.enter="closeKeyboard"
                  />
                </ion-item>

                <div class="d-flex">
                  <ion-item
                    lines="none"
                    style="margin: 0 15px 0 0;"
                  >
                    <ion-label position="floating">
                      {{ $t('Apartment nr.') }}
                    </ion-label>
                    <ion-input
                      id="flat-number"
                      v-model="flatNumber"
                      type="text"
                      enterkeyhint="done"
                      @keydown.enter="closeKeyboard"
                    />
                  </ion-item>
                  <ion-item
                    lines="none"
                    style="margin: 0;"
                  >
                    <ion-label position="floating">
                      {{ $t('Post Code') }}
                    </ion-label>
                    <ion-input
                      id="post-code"
                      v-model="postCode"
                      type="text"
                      required
                      enterkeyhint="done"
                      @keydown.enter="closeKeyboard"
                    />
                  </ion-item>
                </div>
                <ion-button
                  fill="clear"
                  color="transparent"
                  class="next-button mt-20"
                  expand="block"
                  :disabled="!country || !city || !street || !postCode"
                  @click="nextSlide"
                >
                  {{ $t('Continue') }}
                </ion-button>
              </div>

              <ion-row class="mt-20">
                <ion-col>
                  <ion-text class="text--gray">
                    {{ $t('Have an account?') }}
                  </ion-text>
                  <a href="/login">
                    {{ $t('Sign In') }}
                  </a>
                </ion-col>
              </ion-row>
            </ion-content>
          </ion-slide>

          <ion-slide>
            <ion-content class="ion-padding welcome-content">
              <div>
                <h1>
                  {{ $t('Create password') }}
                </h1>
                <ion-text class="text--gray">
                  {{ $t('passwordStrengthText') }}
                </ion-text>
                <ion-item
                  lines="none"
                  style="margin-top: 25px"
                >
                  <ion-label position="floating">
                    {{ $t('password') }}
                  </ion-label>
                  <ion-input
                    v-model="password"
                    enterkeyhint="done"
                    :type="passwordInputModeVisible ? 'text' : 'password'"
                    required
                    @keydown.enter="closeKeyboard"
                  />
                  <div
                    slot="end"
                    @click="passwordInputModeVisible = !passwordInputModeVisible"
                  >
                    <i
                      class="mdi pwd-icon"
                      :class="passwordInputModeVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    />
                  </div>
                </ion-item>
                <ion-item lines="none">
                  <ion-label position="floating">
                    {{ $t('Confirm Password') }}
                  </ion-label>
                  <ion-input
                    v-model="confirmPassword"
                    enterkeyhint="done"
                    :type="passwordInputModeVisibleRepeat ? 'text' : 'password'"
                    required
                    @keydown.enter="closeKeyboard"
                  />
                  <div
                    slot="end"
                    @click="passwordInputModeVisibleRepeat = !passwordInputModeVisibleRepeat"
                  >
                    <i
                      class="mdi pwd-icon"
                      :class="passwordInputModeVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    />
                  </div>
                </ion-item>
                <div style="min-height: 50px;">
                  <password-meter
                    :password="password"
                    @score="passwordScore = $event.score"
                  />
                  <transition
                    name="fade1"
                    mode="out-in"
                  >
                    <ion-label v-if="message.message">
                      <p :class="message.class">
                        {{ message.message }}
                      </p>
                    </ion-label>
                  </transition>
                </div>
                <ion-button
                  fill="clear"
                  color="transparent"
                  class="next-button"
                  expand="block"
                  :disabled="!name || !email || !phoneNumber || !country || !city || !street || !postCode || !password || !confirmPassword || password !== confirmPassword || loading"
                  @click="SignUpNewUser"
                >
                  <ion-spinner
                    v-if="loading"
                    color="dark"
                    name="crescent"
                  />
                  <ion-label v-else>
                    {{ $t('Create account') }}
                  </ion-label>
                </ion-button>
                <ion-row v-if="error">
                  <ion-col>
                    {{ error }}
                  </ion-col>
                </ion-row>
              </div>

              <ion-row class="mt-20">
                <ion-col>
                  <ion-text class="text--gray">
                    {{ $t('Have an account?') }}
                  </ion-text>
                  <a href="/login">
                    {{ $t('Sign In') }}
                  </a>
                </ion-col>
              </ion-row>
            </ion-content>
          </ion-slide>

          <ion-slide>
            <ion-content class="ion-padding welcome-content">
              <div style="margin-top: 25vh">
                <span class="text-64 text--white"><i
                  class="mdi mdi-check-circle"
                  style="color: lightgreen;"
                /></span>
              </div>
              <h1 class="text--white">
                {{ $t('Account created') }}
              </h1>
              <div class="text--gray">
                {{ $t('You may login now') }}
              </div>
            </ion-content>
          </ion-slide>
        </ion-slides>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { alertController, modalController } from '@ionic/vue'
import { Keyboard } from '@capacitor/keyboard'

import SignUpIntro from '@/components/SignUpIntro'
import Header from '../components/Header.vue'
import TAndCModal from '../components/TermsAndConditions/TAndCModal.vue'

import { InAppBrowser } from '@ionic-native/in-app-browser/'

import PasswordMeter from 'vue-simple-password-meter'

const Countries = [
  { label: 'Sverige', value: 'Sweden' }
]

export default {
  name: 'SignUp',
  components: {
    SignUpIntro,
    Header,
    PasswordMeter
  },
  data () {
    return {
      countries: Countries,
      page: 0,
      verificationCode: '',
      verificationFailed: false,
      oAuth: false,
      name: '',
      email: '',
      agreeToTC: false,
      password: '',
      confirmPassword: '',
      passwordScore: 0,
      phoneNumber: '',
      street: '',
      houseNumber: '',
      flatNumber: '',
      city: '',
      postCode: '',
      country: 'Sweden',
      passwordInputModeVisible: false,
      passwordInputModeVisibleRepeat: false,
      isJoining: false,
      error: '',
      loading: false,
      message: {}
    }
  },
  computed: {
    platform () {
      return this.$store.state.platform
    }
  },
  created () {
  },
  methods: {
    OAuthSingUp (data) {
      this.page++
      this.name = data.name
      this.email = data.email
      this.oAuth = true
    },
    sendEmailVerification () {
      this.loading = true
      if (!this.oAuth) {
        this.$store.dispatch('sendEmailVerification', { email: this.email.toLowerCase(), name: this.name.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) })
          .then(response => {
            this.nextSlide()
            this.loading = false
          })
          .catch(error => {
            this.email = ''
            this.loading = false
            this.errorAlert(error.response.data)
          })
      } else {
        this.skipEmailVerification()
        this.loading = false
      }
    },
    verifyMyEmail () {
      this.loading = true
      this.$store.dispatch('verifyMyEmail', { code: this.verificationCode }).then(response => {
        if (response.data.code === 'OK') {
          this.nextSlide()
          this.loading = false
        } else {
          this.verificationFailed = true
          this.loading = false
        }
      })
    },
    nextSlide () {
      this.$refs.slides.$el.slideNext()
    },
    skipEmailVerification () {
      this.$refs.slides.$el.slideTo(2)
    },
    back () {
      if (this.page > 0) {
        this.page--
      }
      if (this.page > 0) {
        this.$refs.slides.$el.slideTo(this.page)
      }
    },
    async SignUpNewUser () {
      if (this.passwordScore <= 3) {
        this.message = { message: this.$t('Password is too weak'), class: 'error' }
        setTimeout(() => { this.message = {} }, 2000)
        this.loading = false
      } else {
        if (this.name && this.email && this.password && this.phoneNumber) {
          this.loading = true
          const city = this.capitalizeFirstLetter(this.city)
          const street = this.capitalizeFirstLetter(this.street)
          const country = this.capitalizeFirstLetter(this.country)
          const name = this.name.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
          await this.$store
            .dispatch('SignUpNewUser', {
              name,
              email: this.email.toLowerCase(),
              password: this.password,
              phone: this.phoneNumber,
              propertyNumber: this.houseNumber,
              flatNumber: this.flatNumber,
              agreedToTC: this.agreeToTC,
              street,
              city,
              postCode: this.postCode,
              language: 'SE',
              country,
              isJoining: this.isJoining
            })
            .then(response => {
            // console.log(response)
              this.nextSlide()
              setTimeout(() => {
                this.$router.replace('/login')
                this.loading = false
                this.clearForm()
              }, 1000)
            })
            .catch(error => {
              this.loading = false
              console.log(error)
            })
        } else {
          this.error = 'Some info is missing'
        }
      }
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    async presentAlert () {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Not Implemented',
          subHeader: '',
          message: '',
          buttons: ['OK']
        })
      await alert.present()

      const { role } = await alert.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    },
    openTCOLD () {
      return modalController
        .create({
          component: TAndCModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openTC () {
      let linkToOpen = ''
      if (this.platform.includes('android')) {
        linkToOpen = 'https://docs.google.com/viewer?url=https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/PrivacyPolicy.pdf'
      } else {
        linkToOpen = 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/PrivacyPolicy.pdf'
      }
      InAppBrowser.create(linkToOpen).show()
    },
    openPP () {
      let linkToOpen = ''
      if (this.platform.includes('android')) {
        linkToOpen = 'https://docs.google.com/viewer?url=https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/IntegritetspolicyHomevision.pdf'
      } else {
        linkToOpen = 'https://homevision-help-center-content.s3.eu-north-1.amazonaws.com/pdfs/new/IntegritetspolicyHomevision.pdf'
      }
      InAppBrowser.create(linkToOpen).show()
    },
    closeModal () {
      modalController.dismiss()
    },
    async errorAlert (message) {
      const alert = await alertController
        .create({
          header: message,
          subHeader: '',
          message: '',
          buttons: ['Dismiss']
        })
      await alert.present()
    },
    closeKeyboard () {
      Keyboard.hide()
    },
    clearForm () {
      this.verificationCode = ''
      this.name = ''
      this.email = ''
      this.agreeToTC = false
      this.password = ''
      this.confirmPassword = ''
      this.passwordScore = 0
      this.phoneNumber = ''
      this.street = ''
      this.houseNumber = ''
      this.flatNumber = ''
      this.city = ''
      this.oAuth = false
    }
  }
}
</script>

<style lang="scss" scoped>

@import '~@/theme/_colors';
.next-button {
  background-color: colors(primary, base);
  padding: 8px 10px 8px 10px;
  border-radius: 5px;
  height: 50px;
}
.slides {
  height: 100vh;
  width: 100%;
}
.red-border {
  border: 1px solid red;
}
// .primary {
//  --backgournd-color: blue;
// }
// .dark {
//   --backgournd-color: grey;
// }
  @media only screen and (min-width: 750px) {
  .container {
    margin-left: 20vw;
    margin-right: 20vw;
  }
    .background--login::after {
    background-size: 100% auto;
  }
}
@media only screen and (min-width: 1000px) {
  .container {
    margin-left: 30vw;
    margin-right: 30vw;
  }
    .background--login::after {
    background-size: 100% auto;
  }
}
@media only screen and (min-width: 1300px) {
  .container {
    margin-left: 35vw;
    margin-right: 35vw;
  }
  .background--login::after {
    background-size: 100% auto;
  }
}
.error {
  color: #eb445a;
}
.success {
  color: #ffd4a4;
}
.gray {
  color: rgb(70, 70, 70);
}
.fade1-enter-active, .fade1-leave-active {
  transition: opacity .2s;
}
.fade1-enter, .fade1-leave-to {
  opacity: 0;
}
.text-64 {
  font-size: 36px;
}
.t-and-c {
  margin-inline-end: 0px!important;
  margin-inline: 0!important;
  --padding-start: 0!important;
  --padding-end: 0!important;
}
@media only screen and (min-width: 750px) {
  .fixed-grid {
    margin-left: 20vw;
    margin-right: 20vw;
  }
}
@media only screen and (min-width: 1000px) {
  .fixed-grid {
    margin-left: 30vw;
    margin-right: 30vw;
  }
}
@media only screen and (min-width: 1300px) {
  .fixed-grid {
    margin-left: 35vw;
    margin-right: 35vw;
  }
}
</style>
